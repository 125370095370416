import { CurrencyPipe, PercentPipe } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { authInterceptor, provideAuth } from 'angular-auth-oidc-client';
import { CrmAppConfig } from 'common-module/core';
import { crmProvideEndpoint } from 'common-module/endpoint';
import { crmProvideLayout } from 'common-module/layout-v2';
import { crmProvideMessage } from 'common-module/message';
import { crmProvideModal } from 'common-module/modal';
import { crmProvideTranslate } from 'common-module/translate';
import { en_US, NZ_DATE_CONFIG, NZ_I18N } from 'ng-zorro-antd/i18n';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { AuthService } from '~/api/auth/auth.service';

import { environment } from '../environments/environment';

import { routes } from './app.routes';
import { crmConfig } from './config/crm.config';
import { endpointConfig } from './config/endpoint.config';
import { layoutConfig } from './config/layout.config';
import { translateConfig } from './config/translate.config';

export const DEFAULT_PRICE_FORMAT = '1.2-4';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([authInterceptor()])),
    provideAnimations(),

    // OpenID
    provideAuth({ config: environment.auth }),

    // NZ
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 } },

    // SWP
    { provide: CrmAppConfig, useValue: crmConfig },
    crmProvideLayout(layoutConfig),
    crmProvideTranslate(translateConfig),
    crmProvideEndpoint(endpointConfig),
    crmProvideModal(),
    crmProvideMessage(),

    // APP
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AuthService],
      useFactory: (user: AuthService) => () => user.checkAuth(),
    },

    { provide: LOCALE_ID, useValue: 'sk' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    CurrencyPipe,
    PercentPipe,
    provideEnvironmentNgxMask(),
  ],
};
